<template>
  <div>
    <el-form label-width="80px"
             :model="form">
      <el-row :gutter="10">

        <el-col :span="12">
          <el-button type="primary"
                     @click="add">新增</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'subject_id', align: 'center', label: '科目编号', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      form: {},
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'slcz_papersubject_admin',
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let params = {
        page,
        limit
      }
      this.$http({
        url: '/api/v2/paper/subject',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    editRow (row) {
      let form = {
        subject_id: row.subject_id,
        subject_name: row.subject_name,
      }
      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    add () {
      let form = {
        subject_name: '',
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((() => {
        this.$http({
          url: '/api/v2/paper/subject_del',
          method: 'POST',
          data: {
            subject_id: row.subject_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  margin-bottom: 15px;
}
</style>